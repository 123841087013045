import React from "react";
import { Container, Image as Img } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import background from "../assets/img/Hintergrund6_16_9.png";
import minbackground from "../assets/img/minimized/Hintergrund6_16_9.png";
import starRating from "../assets/img/icon/star.svg";
import Headline from "./subcomponent/Headline";
import SideScroller from "./subcomponent/SideScroller";

import ueber_uns from "../assets/img/Über uns.png";
import Section from "./Section";

import { useScreenWidth } from "../ScreenWidthContext";

function About() {
  const { t } = useTranslation(["about"]);
  const screenWidth = useScreenWidth();

  async function lazyLoadFunction(e, id) {
    let imageElement = document.getElementById("lazyElementOf" + id);
    if (imageElement) {
      let parent = imageElement.parentNode;
      parent.removeChild(imageElement);
      e.target.classList.remove("bg");
    }
  }

  return (
    <div id="a-about" className="about-content">
      <Headline text={t("project_genesis.about.headline")} styles={{"text-transform": "uppercase"}}/>
      <div className="about-content-holder">
        <span className="about-note">{t("project_genesis.about.note")}</span>
        <Img loading="lazy" src={ueber_uns} className="about-img" />
      </div>
      {screenWidth > 550 && (
        <div className="ratings-holder">
          <div className="thirdPartyRatingDefaults thirdpartyrating1">
            <p>{t("project_genesis.about.bestrooms")}</p>
            <p>{t("project_genesis.about.ratedbyescaperoomers")}</p>
            <a href="https://www.escaperoomers.de">www.escaperoomers.de</a>
          </div>
          <div className="thirdPartyRatingDefaults thirdpartyrating2">
            <p>
              5,0
              <img src={starRating} alt="Star" />
              <img src={starRating} alt="Star" />
              <img src={starRating} alt="Star" />
              <img src={starRating} alt="Star" />
              <img src={starRating} alt="Star" />
            </p>
            <p>{t("project_genesis.about.googlerated")}</p>
          </div>
          <div className="thirdPartyRatingDefaults thirdpartyrating3">
            <p>{t("project_genesis.about.bestescaperoom20202021")}</p>
            <p>{t("project_genesis.about.ratedbyescapemaniacs")}</p>
            <a href="https://www.escape-maniac.com">www.escape-maniac.com</a>
          </div>
        </div>
      )}

      {screenWidth <= 550 && (
        <div className="mobile-ratings-holder">
          <SideScroller classList="about-container-mobile scroller">
            <div className="thirdPartyRatingDefaults thirdpartyrating1">
              <p>{t("project_genesis.about.bestrooms")}</p>
              <p>{t("project_genesis.about.ratedbyescaperoomers")}</p>
              <a href="https://www.escaperoomers.de">www.escaperoomers.de</a>
            </div>
            <div className="thirdPartyRatingDefaults thirdpartyrating2">
              <p>
                5,0
                <img src={starRating} alt="Star" />
                <img src={starRating} alt="Star" />
                <img src={starRating} alt="Star" />
                <img src={starRating} alt="Star" />
                <img src={starRating} alt="Star" />
              </p>
              <p>{t("project_genesis.about.googlerated")}</p>
            </div>
            <div className="thirdPartyRatingDefaults thirdpartyrating3">
              <p>{t("project_genesis.about.bestescaperoom20202021")}</p>
              <p>{t("project_genesis.about.ratedbyescapemaniacs")}</p>
              <a href="https://www.escape-maniac.com">www.escape-maniac.com</a>
            </div>
          </SideScroller>
        </div>
      )}
    </div>
  );
}

export default About;
