import React, { useEffect, useRef, useState, lazy, Suspense } from "react";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ScreenWidthProvider } from "./ScreenWidthContext";

import Explore from "./component/ExploreSection";
import MenuBar from "./component/MenuBar";
import Welcome from "./component/WelcomeSection";
import HowTo from "./component/HowToSection";
import ContactRedesign from "./component/contactRedesign";
//import TourPlanner from './component/TourPlannerSection';
import About from "./component/AboutSection";
import Burger from "./component/subcomponent/Burger";
import FootLine from "./component/FootLine";
import AdventureShowcase from "./component/AdventureShowcase";
import Contact from "./component/ContactSection";
import AdventureShowcaseMobile from "./component/AdventureShowcaseMobile";
import WrapperSection from "./component/WrapperSection";

//import TourPlannerMobileSection from './component/mobile_tour_planner/TourPlannerMobileSection';
import MobileAbout from "./component/MobileAbout";
import { setAcceptedCookieCategories } from "./redux/slices/CookiesSlice";

import { useDispatch, useSelector } from "react-redux";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { cookieSlice } from "./redux/slices/CookiesSlice";
import TagManager from "react-gtm-module";
import LoadingTourPlannerSection from "./component/subcomponent/LoadingTourPlannerSection";
import Explenation from "./component/Explanation";
import backgroundContactFaq from "./assets/img/Backgrounds/Hintergrund_4.png";
import backgroundExploreAbout from "./assets/img/Backgrounds/Hintergrund_5.png";

const TourPlanner = lazy(() => import("./component/TourPlannerSection"));
const SimpleTourPlannerSection = lazy(() =>
  import("./component/SimpleTourPlannerSection")
);
const TourPlannerMobileSection = lazy(() =>
  import("./component/mobile_tour_planner/TourPlannerMobileSection")
);
const FAQ = lazy(() => import("./component/FAQSection"));
const FAQDropdowns = lazy(() => import("./component/FAQDropdowns"));

export function moveToSectionOnLoad() {
  const hash = window.location.hash;
  if (hash) {
    const element = document.getElementById(hash.slice(1));
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "instant", block: "start" });
      }, 10);
    }
  }
}

var changed = false;
// function checkOrientation() {
//   let landscape = false;
//   switch (window.screen.orientation.angle) {
//     case 90:
//     case 270:
//       landscape = true;
//       break;
//   }
//   if (landscape) {
//     if (changed) return;
//     if (window.innerWidth < 700 && window.innerHeight < 400) {
//       document
//         .querySelector("meta[name=viewport]")
//         .setAttribute(
//           "content",
//           "height=" +
//             window.innerWidth * 2 +
//             ", width=" +
//             window.innerHeight * 2 +
//             ", initial-scale=-1, target-densityDpi=device-dpi, interactive-widget=overlays-content"
//         );
//     } else {
//       document
//         .querySelector("meta[name=viewport]")
//         .setAttribute(
//           "content",
//           "height=" +
//             window.innerHeight * 2 +
//             ", width=" +
//             window.innerWidth * 2 +
//             ", initial-scale=-1, target-densityDpi=device-dpi, interactive-widget=overlays-content"
//         );
//     }
//     changed = true;
//   } else {
//     if (!changed && !isMobileQuery.matches) return;
//     changed = false;
//     let initialHeight = window.innerHeight;
//     document
//       .querySelector("meta[name=viewport]")
//       .setAttribute(
//         "content",
//         "height=" +
//           initialHeight +
//           ", width=device-width, initial-scale=1, minimum-scale=1, target-densityDpi=device-dpi, interactive-widget=overlays-content"
//       );
//     isMobileQuery.onchange?.({ matches: true });
//   }
// }

// window.onload = (ev) => {
//   checkOrientation();
// };

// window.onresize = (ev) => {
//   checkOrientation();
// };

// const mobileMediaQuery =
//   "(max-width: 768px) or ((min-width: 767px) and (max-width: 1024px) and (min-height: 1024px))";
// const responsiveMedia = "(max-width: 512px)";
// export const isMobileQuery = window.matchMedia(mobileMediaQuery);
// export const responsiveQuery = window.matchMedia(responsiveMedia);
export var isMobile = undefined;
export var responsive = undefined;

function App() {
  const acceptedCookieCategories = useSelector(
    (state) => state.cookieSlice.acceptedCookieCategories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    moveToSectionOnLoad();
    document.documentElement.classList.add("cc--darkmode");
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "cloud inline",
          position: "bottom",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {},
      },
      language: {
        default: "de",
        autoDetect: "browser",
        translations: {
          de: {
            consentModal: {
              title: "Wir verwenden Cookies!",
              description:
                "Diese Website verwendet essentielle Cookies, um sicherzustellen, dass sie ordnungsgemäß funktioniert, und Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Zustimmung gesetzt.",
              acceptAllBtn: "Alle akzeptieren",
              acceptNecessaryBtn: "Nur notwendige",
              showPreferencesBtn: "Einstellungen verwalten",
              footer:
                '<a href="/dataprotection">Datenschutz</a>\n<a href="/agb">AGB</a>',
            },
            preferencesModal: {
              title: "Cookie-Einstellungen",
              acceptAllBtn: "Alle akzeptieren",
              acceptNecessaryBtn: "Alle ablehnen",
              savePreferencesBtn: "Einstellungen speichern",
              closeIconLabel: "Schließen",
              serviceCounterLabel: "Dienst|Dienste",
              sections: [
                {
                  title: "Cookie-Zustimmung",
                  description:
                    "Wir verwenden notwendige Cookies, damit unsere Webseite funktioniert. Wir möchten zusätzliche Cookies setzen, um die Nutzung der Webseite zu verstehen, die Webseite zu verbessern und um Ihre Einstellungen zu speichern. Wir verwenden auch Cookies, die von anderen Webseiten gesetzt werden, um die Bereitstellung von Inhalten aus deren Diensten zu unterstützen.",
                },
                {
                  title:
                    'Streng notwendige Cookies <span class="pm__badge">Immer Aktiviert</span>',
                  description:
                    "Diese Cookies sind für das Funktionieren der Website unerlässlich und können in unseren Systemen nicht deaktiviert werden.",
                  linkedCategory: "necessary",
                  cookieTable: {
                    headers: {
                      name: "Name",
                      domain: "Service",
                      description: "Beschreibung",
                      expiration: "Ablauf",
                    },
                    body: [
                      {
                        name: "AEC",
                        domain: "google.com",
                        description:
                          'wird von Google verwendet, sicherzustellen, dass Anfragen innerhalb einer Browsersitzung konsistent bleiben. Er wird verwendet, um sicherzustellen, dass Anfragen von einem Nutzer und nicht von bösartigen Akteuren stammen. <a href="https://policies.google.com/technologies/cookies?hl=en-US">Mehr</a>',
                        expiration: "13 Monate",
                      },
                      {
                        name: "NID",
                        domain: "google.com",
                        description:
                          'wird von Google verwendet, um Ihre Präferenzen und andere Informationen zu speichern, wie z.B. Ihre bevorzugte Sprache, die Anzahl der Suchergebnisse pro Seite und ob der SafeSearch-Filter aktiviert ist. <a href="https://policies.google.com/technologies/cookies?hl=en-US">Mehr</a>',
                        expiration: "1 Jahr",
                      },
                      {
                        name: "SOCS",
                        domain: "google.com",
                        description:
                          'wird von Google verwendet, um die Zustimmungseinstellungen der Nutzer zu speichern. Dieser Cookie hilft Google dabei, die Einwilligungen der Nutzer in Bezug auf Datenschutz und Cookie-Nutzung zu verwalten. <a href="https://policies.google.com/technologies/cookies?hl=en-US">Mehr</a>',
                        expiration: "1 Jahr",
                      },
                      {
                        name: "Paypal",
                        domain: "paypal.com",
                        description:
                          "Diese Cookies werden verwendet, um Zahlungen über Paypal zu ermöglichen.",
                        expiration: "1 Jahr",
                      },
                      {
                        name: "_GRECATCHA",
                        domain: "google.com",
                        description:
                          "Google Recaptcha. Diese Cookies werden verwendet, um sicherzustellen, dass Sie kein Roboter sind.",
                        expiration: "1 Jahr",
                      },
                    ],
                  },
                },
                {
                  title: "Analytische Cookies",
                  description:
                    "Diese Cookies helfen uns, die Nutzung unserer Website zu analysieren und zu verstehen.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Name",
                      domain: "Service",
                      description: "Description",
                      expiration: "Expiration",
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: "google.com",
                        description:
                          'Wird von Google Analytics verwendet, um einzelne Nutzer zu unterscheiden. Er speichert eine eindeutige ID, die verwendet wird, um statistische Daten darüber zu sammeln, wie der Besucher die Website nutzt. <a href="https://policies.google.com/technologies/cookies?hl=en-US">Mehr</a>',
                        expiration: "2 Jahre",
                      },
                      {
                        name: "_gid",
                        domain: "google.com",
                        description:
                          'Wird ebenfalls von Google Analytics verwendet, um Nutzer zu unterscheiden. Er speichert und aktualisiert einen eindeutigen Wert für jede besuchte Seite. <a href="https://policies.google.com/technologies/cookies?hl=en-US">Mehr</a>',
                        expiration: "24 Stunden",
                      },
                      {
                        name: "_fbp",
                        domain: "facebook.com",
                        description:
                          'Cookie gesetzt von <a href="#facebook-pixel">Facebook Pixel</a>',
                        expiration: "90 Tage",
                      },
                      {
                        name: "fr",
                        domain: "facebook.com",
                        description:
                          'Cookie gesetzt von <a href="#facebook-pixel">Facebook Pixel</a>',
                        expiration: "90 Tage",
                      },
                    ],
                  },
                },
                {
                  title: "Weitere Informationen",
                  description:
                    'Bei Fragen zu meiner Cookie-Richtlinie und Ihren Optionen, kontaktieren Sie uns bitte <a class="cc__link" href="#a-contact">hier</a>.',
                },
              ],
            },
          },
        },
      },

      onConsent: ({ cookie }) => {
        dispatch(setAcceptedCookieCategories(cookie.categories));
      },
    });

    document.addEventListener("cc:consent", (event) => {
      const preferences = CookieConsent.getUserPreferences();
      // Hier kannst du weitere Aktionen basierend auf den Präferenzen durchführen
    });
  }, []);

  useEffect(() => {
    const analyticsCookiesAccepted =
      acceptedCookieCategories.includes("analytics");
    if (analyticsCookiesAccepted) {
      const TAG_MANAGER_CONTAINER_ID =
        window.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID;

      const tagManagerArgs = {
        gtmId: TAG_MANAGER_CONTAINER_ID,
      };
      TagManager.initialize(tagManagerArgs);
      //console.log("Tag Manager wurde intialisiert");
    }
  }, [acceptedCookieCategories]);

  // const [mobile, setIsMobile] = useState(isMobileQuery.matches);
  // const [resp, setResp] = useState(responsiveQuery.matches);
  // isMobile = mobile;
  // responsive = resp;

  const isSimpleMode = true;

  // const changeAppComponents = (e) => {
  //   setIsMobile(e.matches);
  // };
  // const changeBackgroundComponents = (e) => {
  //   setResp(e.matches);
  // };
  // isMobileQuery.onchange = changeAppComponents;
  // responsiveQuery.onchange = changeBackgroundComponents;

  const instantHowTo = useRef(isMobile);
  const instantExplore = useRef(isMobile);

  //   if (mobile) {
  //     return (
  //       <main>
  //         <I18nextProvider i18n={i18n}>
  //           <Burger />
  //           <MenuBar />
  //           <Welcome />
  //           <Explore instant={instantExplore} />
  // {/*           <HowTo instant={instantHowTo} /> */}
  //           {/* <AdventureShowcaseMobile /> */}
  //           <Suspense fallback={<LoadingTourPlannerSection/>}>
  //             <TourPlannerMobileSection />
  //             <FAQDropdowns />
  //           </Suspense>
  //           {/* <Contact /> */}
  //           <MobileAbout />
  //           <FootLine />
  //         </I18nextProvider>
  //       </main>
  //     );
  //   }
  return (
    <main>
      <ScreenWidthProvider>
        <I18nextProvider i18n={i18n}>
          <Burger />
          <MenuBar />
          <Welcome />
          {/* <HowTo instant={instantHowTo} /> */}
          {/* <AdventureShowcase /> */}
          <Suspense fallback={<LoadingTourPlannerSection />}>
            {isSimpleMode && <SimpleTourPlannerSection />}
            {!isSimpleMode && <TourPlanner />}
          </Suspense>
          <Explenation sections={5} />
          <WrapperSection bg={backgroundContactFaq} minbg={backgroundContactFaq}>
            <ContactRedesign />
            <FAQ />
          </WrapperSection>

          <WrapperSection bg={backgroundExploreAbout} minbg={backgroundExploreAbout}>
            <Explore instant={instantExplore} />
            <About />
          </WrapperSection>
          <FootLine />
        </I18nextProvider>
      </ScreenWidthProvider>
    </main>
  );
}

export default App;
