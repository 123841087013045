import React from "react";
import { Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import background from "../assets/img/Hintergrund1_16_9.png";
import minbackground from "../assets/img/minimized/Hintergrund1_16_9.png";
import res_bg from "../assets/img/responsive_backgrounds/Background1_Responsive.png";
import min_res_bg from "../assets/img/responsive_backgrounds/minimized/Background1_Responsive.png";
import { isMobile, responsive } from "../App";
import logo from "../assets/img/logos/Logo.svg";
import Section from "./Section";
import priceTagImage from "../assets/img/Etikett_Preis_ot.png";
import qrPhone from "../assets/img/Qr-phone.png";
import buttonPhone from "../assets/img/Responsive_Handy_Testtour.png";
import Button from "react-bootstrap/Button";

export function hidemenu(e, force = false) {
  if (isMobile) {
    hidemenumobile(e, force);
    return;
  }
  var burgerSymbol = document.getElementById("burgerSymbol");
  var phoneSymbol = document.getElementById("phoneSymbol");
  var navBar = document.getElementById("navbar");
  if (
    force ||
    (navBar.classList.contains("show") &&
      navBar.getBoundingClientRect().width < e.clientX)
  ) {
    burgerSymbol.classList.remove("clicked");
    navBar.classList.remove("show");
    phoneSymbol.style.display = null;
    phoneSymbol.classList.remove("hide");
  }
}

export function hidemenumobile(e, force = false) {
  var burgerSymbol = document.getElementById("burgerSymbol");
  var navBar = document.getElementById("navbar");
  if (
    force ||
    (navBar.classList.contains("show") &&
      navBar.getBoundingClientRect().width < e.clientX)
  ) {
    burgerSymbol.classList.remove("clicked");
    navBar.classList.remove("show");
  }
}

export function hidemenusub(e, force = false) {
  var burgerSymbol = document.getElementById("burgerSymbol");
  var navBar = document.getElementById("navbar");
  if (
    force ||
    (navBar.classList.contains("show") &&
      navBar.getBoundingClientRect().width < e.clientX)
  ) {
    burgerSymbol.classList.remove("clicked");
    navBar.classList.remove("show");
  }
}

function Welcome() {
  const { t } = useTranslation(["welcome_explore"]);

  window.addEventListener("click", hidemenu);

  const hideYoAss = (e) => {
    var s = document.getElementById("phoneSymbol");
    if (s !== null && s.classList.contains("hide")) {
      s.style.display = "none";
    }
  };

  const scrollHandler = (ev) => {
    var qrPhone = document.getElementById("qrPhone");
    var buttonPhone = document.getElementById("buttonPhone");
    if (qrPhone){
        var welcomeImage = document.querySelector("#a-welcome > img");
        var tourPlannerImage = document.querySelector("#a-tourplanner > img");
        if (welcomeImage === null || tourPlannerImage === null) return;
    
        if (welcomeImage.getBoundingClientRect().bottom - window.scrollY < 470) {
          if (!qrPhone.classList.contains("phone-hidden")) {
            // Check if it already has class assigned, just in case
            qrPhone.classList.add("phone-hidden");
          }
        } else if (qrPhone.classList.contains("phone-hidden")) {
          qrPhone.classList.remove("phone-hidden");
        }
    }
    if (buttonPhone){
        var welcomeImage = document.querySelector("#a-welcome > img");
        var tourPlannerImage = document.querySelector("#a-tourplanner > img");
        if (welcomeImage === null || tourPlannerImage === null) return;
    
        if (welcomeImage.getBoundingClientRect().bottom - window.scrollY < 470) {
          if (!buttonPhone.classList.contains("phone-hidden")) {
            // Check if it already has class assigned, just in case
            buttonPhone.classList.add("phone-hidden");
          }
        } else if (buttonPhone.classList.contains("phone-hidden")) {
            buttonPhone.classList.remove("phone-hidden");
        }
    }
    
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <Section
      id="a-welcome"
      bg={responsive ? res_bg : background}
      minbg={responsive ? min_res_bg : minbackground}
    >
      <object type="image/svg+xml" className="welcome-logo" data={logo} />

      <div className="price-tag">
        <img
          src={priceTagImage}
          alt="Preisschild"
          className="price-tag-image"
        />
        <div class="price-tag-text-container">
          <div>
            <div class="price-tag-text">14,90€</div>
            <div class="price-tag-text-desc">PRO SCHÜLER</div>
          </div>
        </div>
      </div>

      <div className="qr-phone" id="qrPhone">
        <img src={qrPhone} alt="QR-Code" className="qr-phone-image" />
      </div>

      <div className="button-phone" id="buttonPhone">
        <img src={buttonPhone} alt="Demo" className="button-phone-image" />
        <Button className="button-phone-description" 
        onClick={() => window.open('https://espoto.tabgame.de/system/launch/a/espoto_app_secret/gametype/1/e/55416/d/MTQzNTA2X2VmMzg2YTdjMmZkMWNkMjE3MWU4Njg0YjQxMWMxZmNiM2Y1NTA0NjVfNTU0MTY=', '_blank')}>
          {t("project_genesis.welcome.phone_button_text")}
        </Button>
      </div>

      <div className="welcome-headline">
{/*         <div>
          <span></span>
          <h3 className="smaller-line">
            {t("project_genesis.welcome.headline")}
          </h3>
        </div> */}
        <h1 className="smaller-line">
          {t("project_genesis.welcome.big-headline-a")}
        </h1>
        <h2 className="smaller-line">
          {t("project_genesis.welcome.big-headline-b")}
        </h2>
      </div>
      {!isMobile && (
        <div
          className="phone-div"
          id="phoneSymbol"
          onClick={(ev) => {
            if (!ev.target.classList.contains("hide")) {
              document.location.href =
                "tel:" + t("project_genesis.welcome.phone_number");
            }
          }}
          onTransitionEnd={hideYoAss}
        >
          <div className="phone"></div>
          <span className="phone-tooltip">
            {t("project_genesis.welcome.phone_number")}
          </span>
        </div>
      )}
    </Section>
  );
}

export default Welcome;
